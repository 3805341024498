import { Box, Button, Heading, Label, StyledOcticon, Text, } from "@primer/react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ArrowUpIcon, BookIcon } from "@primer/octicons-react"
import TaxHeroHeader from "../Portal/TaxHeroHeader"
import { getUserOrganizationById } from "../redux/features/spaceSlice"
import React from "react"
import { checkUserSubscriptionStatus, openCloseTaxheroFreeTrialEndDialog } from "../redux/features/profileSlice"
import { getProductTourTaxhero, getTaxheroPortalStatic, updateProductTourTaxhero } from "../redux/features/portalSlice"
import axios from "axios"
import CalandarComponent from "./CalandarComponent"
import GstFilingSnapshotComponent from "./GstFilingSnapshotComponent"

const rightImage = `${process.env.REACT_APP_CLOUDFRONTURL}/Template/rightColumnImg.svg`
const itImg = `${process.env.REACT_APP_S3BUCKETURL}/Template/itImg.svg`
const treeImg = `${process.env.REACT_APP_CLOUDFRONTURL}/Template/treeImg.svg`
const flyingImg = `${process.env.REACT_APP_CLOUDFRONTURL}/Template/flyingImg.svg`

function TaxHeroPortalPlaywright(props) {

    let host = window.location.host
    let hostName = window.location.hostname
    const Sprig = window?.Sprig
    const driver = window.driver.js.driver;
    const driverObj = driver({
        popoverClass: 'driverjs-theme',
        showProgress: true,
        allowClose: false,
        steps: [
            { element: '#itBox', popover: { title: 'IT', description: 'View all your Income Tax notices, demand status, and return status in one dashboard with actionable reports.', side: "top", align: 'start' } },
            { element: '#gstBox', popover: { title: 'GST', description: 'View all your GST notices, demand status, and return status in one dashboard with actionable reports.', side: "top", align: 'start' } },
            // { element: '#tdsBox', popover: { title: 'TDS', description: 'You can use the default class names and override the styles or you can pass a custom class name to the popoverClass option either globally or per step.', side: "top", align: 'start' } },

        ],
        onDestroyStarted: () => {

            if (driverObj.getActiveIndex() == 1) {
                updateProductTourInDb(userId)
                if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                    driverObj.destroy();

                }

            }

        },


    });

    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const taxherofreetrialEndOpen = useSelector((state) => state.profileData.taxherofreetrialEndOpen)
    const growsubscriptionstatus = useSelector((state) => state.profileData.growsubscriptionstatus)
    const taxHerosubscriptionstatus = useSelector((state) => state.profileData.taxHerosubscriptionstatus)
    const [subscriptionStatus, setsubscriptionStatus] = React.useState({
        grow: growsubscriptionstatus,
        taxHero: taxHerosubscriptionstatus
    })
    const [isOrgSetup, setisOrgSetup] = React.useState(false)
    const [taxHeroStatic, settaxHeroStatic] = React.useState({
        itCount: 0,
        itSyncPercentage: 0,
        gstCount: 0,
        gstSyncPercentage: 0,
        tdsCount: 0,
        tdsSyncPercentage: 0
    })

    //---------------- calandar section -----------

    //---------------------------------------------
    //get user defaults
    const getUserDefaults = (props) => {

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getUserOrganizationById", data.payload)
            if (data?.payload?.data[0]?.verification === "true") {
                setisOrgSetup(true)
                // getUserProductTour("")
            } else {
                setisOrgSetup(false)
                navigate('/accountsetup')
            }

        })

    }

    const showFreeTrialEnd = (props) => {
        dispatch(openCloseTaxheroFreeTrialEndDialog({
            taxherofreetrialEndOpen: true,
        }))
    }

    const checkUserSubscriptionStatusFromApi = async (props) => {

        dispatch(checkUserSubscriptionStatus({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {
            console.log("checkUserSubscriptionStatus", data.payload)
            const growsubscriptionstatus = data?.payload?.growsubscription?.list[0]?.subscription?.status || "0"
            const taxHerosubscriptionstatus = data?.payload?.taxHerosubscription?.list[0]?.subscription?.status || "0"
            setsubscriptionStatus((prevState) => {
                return ({ ...prevState, grow: growsubscriptionstatus, taxHero: taxHerosubscriptionstatus })
            })
        })
        
    }

    // Track active users
    const trackActiveUser = (props) => {
        if (userId !== null && userId !== "") {

            Sprig('setUserId', userId)
            Sprig('identifyAndTrack', {
                eventName: 'ActiveUser',
                userId: userId,
                properties: { user_id: userId, user_email: userEmail }
            });
            Sprig('setEmail', userEmail);
            Sprig('setAttributes', {
                activeUserId: userId,
                activeUserEmail: userEmail
            });
        }
    }

    // Show survey form when button click
    const showSurveyForm = (props) => {
        if (userId !== null && userId !== "") {

            Sprig('identifyAndTrack', {
                eventName: 'FeedbackForm',
                userId: userId,
                properties: { user_id: userId, user_email: userEmail }
            });
            Sprig('displaySurvey', '123382')


        }
    }

    const postHogTracking = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'LiveUsers',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }
    }

    const postHogTrackingItrClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'ITRClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }

    const postHogTrackingGstClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'GSTClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }



    }

    const postHogTrackingTdsClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'TDSClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }

    const showProductTour = (props) => {
        driverObj.drive();
    }

    const updateProductTourInDb = (props) => {

        const locationPath = [{ pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }

    const getUserProductTour = (props) => {

        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            //remove 0 for working product tour
            const checkProductTour = [...data.payload?.data]?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                // showProductTour("")
            } else {


            }


        })

    }

    const getTaxheroPortalStaticFromDb = (props) => {

        dispatch(getTaxheroPortalStatic({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getTaxheroPortalStatic", data.payload)
            settaxHeroStatic(data.payload.data)
        })

    }

    React.useEffect(() => {

        if (taxherofreetrialEndOpen) {
            driverObj.destroy();
        }

    }, [taxherofreetrialEndOpen])


    React.useState(() => {
        checkUserSubscriptionStatusFromApi("")
        getUserDefaults("")
        // getSubscriptionData("")
        // checkTaxHeroSubscription("")
        //For showing active users in Sprig console
        trackActiveUser("")
        getTaxheroPortalStaticFromDb("")
        postHogTracking(userId, userEmail)
    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F6F6"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'Portal - taxhero' : 'Portal - taxhero'}
                </title>
            </Helmet>

            <TaxHeroHeader />

            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '64px',
                display: isOrgSetup ? 'flex' : 'none',
                flexDirection: 'column',
                minHeight: 'fit-content'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    margin: '0px auto 0px auto',
                    width: '1144px',
                    minWidth: '1144px',
                    maxWidth: '1144px',
                    // minHeight: '100vh',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                        width: '1144px',
                        minWidth: '1144px',
                        maxWidth: '1144px',
                        minHeight: '225px',
                        maxHeight: '225px',
                        height: '225px',
                        marginTop: '28px'
                    }}>

                        <Box sx={{
                            width: '720px',
                            minWidth: '720px',
                            maxWidth: '720px',
                            minHeight: '225px',
                            maxHeight: '225px',
                            height: '225px',
                            background: '#2BEBC8',
                            border: '1px solid #D0D7DE',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'row',

                        }}>

                            <Box sx={{
                                width: '348px',
                                minWidth: '348px',
                                maxWidth: '348px',
                                minHeight: '201px',
                                maxHeight: '201px',
                                height: '201px',
                                position: 'relative',
                                margin: '12px 0px 12px 12px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px'
                            }}>

                                <Heading sx={{
                                    position: 'relative',
                                    margin: '24px 24px 0px 24px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '26px',
                                    color: '#24292E',
                                }}>
                                    Welcome to Taxhero
                                </Heading>
                                <Text sx={{
                                    position: 'relative',
                                    margin: '0px 24px 0px 24px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    Automates data fetching from Income tax, GST, and TDS portals. It offers a single dashboard where you can easily view and manage all your GST, Income Tax, and TDS notices, demand status, and return status.
                                </Text>


                            </Box>


                            <Box sx={{
                                width: '348px',
                                minWidth: '348px',
                                maxWidth: '348px',
                                minHeight: '201px',
                                maxHeight: '201px',
                                height: '201px',
                                position: 'relative',
                                margin: '12px 0px 12px 0px',

                            }}>
                                <img src={rightImage} >
                                </img>
                            </Box>






                        </Box>

                        <Box sx={{
                            width: '397px',
                            minWidth: '397px',
                            maxWidth: '397px',
                            minHeight: '225px',
                            maxHeight: '225px',
                            height: '225px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            border: '3px solid #ffffff',
                            cursor: 'pointer',
                            ":hover": {
                                border: '3px solid #0969DA'
                            }
                        }}
                            onClick={() => {
                                // navigate('/activity')
                            }}

                        >

                            <Box

                                sx={{
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    margin: '57px  auto auto auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: '106px',
                                    width: '337px',
                                    borderRadius: '6px',

                                }}>

                                <Box sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px'
                                }}>
                                    <BookIcon size={24} />
                                </Box>



                                <Heading
                                    sx={{
                                        position: 'relative',
                                        margin: '16px 0px 0px 0px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                        color: '#24292E',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Activity
                                </Heading>
                                <Text sx={{
                                    width: '337px',
                                    height: '40px',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: '#586069',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}>
                                    This feature has not been released yet. It will be available very soon.
                                </Text>


                            </Box>



                        </Box>



                    </Box>



                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                        width: '1144px',
                        minWidth: '1144px',
                        maxWidth: '1144px',
                        minHeight: '146px',
                        maxHeight: '146px',
                        height: '146px',
                        marginTop: '24px',


                    }}>

                        <Box
                            id="itBox"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '343px',
                                minWidth: '343px',
                                maxWidth: '343px',
                                minHeight: '146px',
                                maxHeight: '146px',
                                height: '146px',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                border: '3px solid #ffffff',
                                ":hover": {
                                    border: '3px solid #0969DA'
                                }

                            }}
                            onClick={() => {

                                postHogTrackingItrClick(userId, userEmail)

                                // switch (subscriptionStatus.grow) {
                                //     case "cancelled":
                                //         if (subscriptionStatus.taxHero === "0" || subscriptionStatus.taxHero === "cancelled") {
                                //             showFreeTrialEnd("");
                                //         } else if (subscriptionStatus.taxHero === "active") {
                                //             navigate('/itpanlist');
                                //         }
                                //         break;

                                //     case "in_trial":
                                //         if (subscriptionStatus.taxHero === "0") {
                                //             navigate('/itpanlist');
                                //         }
                                //         break;

                                //     case "active":
                                //         if (subscriptionStatus.taxHero === "0") {
                                //             showFreeTrialEnd("");
                                //         } else if (subscriptionStatus.taxHero === "active") {
                                //             navigate('/itpanlist');
                                //         }
                                //         break;

                                //     default:
                                //         break;
                                // }

                                 
                                switch (subscriptionStatus.taxHero) {
                                    case "cancelled":
                                        showFreeTrialEnd("");
                                        break;

                                    case "in_trial":
                                        navigate('/itpanlist');
                                        break;

                                    case "active":
                                        navigate('/itpanlist');
                                        break;

                                    default:
                                        showFreeTrialEnd("");
                                        break;
                                }

                                  


                            }}
                        >

                            <Box sx={{
                                width: '168px',
                                minWidth: '168px',
                                maxWidth: '168px',
                                minHeight: '98px',
                                maxHeight: '98px',
                                height: '98px',
                                position: 'relative',
                                margin: '21px 0px 21px 21px',
                                display: 'flex',
                                flexDirection: 'column',

                            }}>

                                <Text sx={{

                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    {taxHeroStatic?.itCount}
                                </Text>
                                <Text sx={{
                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '22px',
                                    color: '#24292F',
                                    marginTop: '4px'
                                }}>
                                    IT
                                </Text>

                                <Box sx={{
                                    marginTop: '18px',
                                    width: '174px',
                                    height: '22px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <StyledOcticon size={15} color="#06AA8D" icon={ArrowUpIcon}></StyledOcticon>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '22px',
                                        color: '#000000',
                                        marginLeft: '8px',
                                    }}>
                                        1.10% Synced 4 days ago
                                    </Text>

                                </Box>


                            </Box>


                            <Box sx={{
                                width: '126px',
                                minWidth: '126px',
                                maxWidth: '126px',
                                minHeight: '126px',
                                maxHeight: '126px',
                                height: '126px',
                                position: 'relative',
                                margin: '10px 0px 10px 7px',

                            }}>

                                <img
                                    src={itImg}
                                >
                                </img>

                            </Box>


                        </Box>

                        <Box
                            id="gstBox"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '343px',
                                minWidth: '343px',
                                maxWidth: '343px',
                                minHeight: '146px',
                                maxHeight: '146px',
                                height: '146px',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                border: '3px solid #ffffff',
                                ":hover": {
                                    border: '3px solid #0969DA'
                                }
                            }}
                            onClick={() => {

                                postHogTrackingGstClick(userId, userEmail)

                                // switch (subscriptionStatus.grow) {
                                //     case "cancelled":
                                //         if (subscriptionStatus.taxHero === "0" || subscriptionStatus.taxHero === "cancelled") {
                                //             showFreeTrialEnd("");
                                //         } else if (subscriptionStatus.taxHero === "active") {
                                //             navigate('/gstpanlist');
                                //         }
                                //         break;

                                //     case "in_trial":
                                //         if (subscriptionStatus.taxHero === "0") {
                                //             navigate('/gstpanlist');
                                //         }
                                //         break;

                                //     case "active":
                                //         if (subscriptionStatus.taxHero === "0") {
                                //             showFreeTrialEnd("");
                                //         } else if (subscriptionStatus.taxHero === "active") {
                                //             navigate('/gstpanlist');
                                //         }
                                //         break;

                                //     default:
                                //         break;
                                // }

                                switch (subscriptionStatus.taxHero) {
                                    case "cancelled":
                                        showFreeTrialEnd("");
                                        break;

                                    case "in_trial":
                                        navigate('/gstpanlist');
                                        break;

                                    case "active":
                                        navigate('/gstpanlist');
                                        break;

                                    default:
                                        showFreeTrialEnd("");
                                        break;
                                }
 

                            }}
                        >


                            <Box sx={{
                                width: '168px',
                                minWidth: '168px',
                                maxWidth: '168px',
                                minHeight: '98px',
                                maxHeight: '98px',
                                height: '98px',
                                position: 'relative',
                                margin: '21px 0px 21px 21px',
                                display: 'flex',
                                flexDirection: 'column',

                            }}>

                                <Text sx={{

                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    {taxHeroStatic?.gstCount}
                                </Text>
                                <Text sx={{
                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '22px',
                                    color: '#24292F',
                                    marginTop: '4px'
                                }}>
                                    GST
                                </Text>

                                <Box sx={{
                                    marginTop: '18px',
                                    width: '174px',
                                    height: '22px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <StyledOcticon size={15} color="#06AA8D" icon={ArrowUpIcon}></StyledOcticon>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '22px',
                                        color: '#000000',
                                        marginLeft: '8px',
                                    }}>
                                        1.10% Synced 4 days ago
                                    </Text>

                                </Box>


                            </Box>

                            <Box sx={{
                                width: '126px',
                                minWidth: '126px',
                                maxWidth: '126px',
                                minHeight: '126px',
                                maxHeight: '126px',
                                height: '126px',
                                position: 'relative',
                                margin: '10px 0px 10px 7px',

                            }}>

                                <img
                                    src={treeImg}
                                >
                                </img>

                            </Box>






                        </Box>

                        <Box
                            id="tdsBox"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '394px',
                                minWidth: '397px',
                                maxWidth: '397px',
                                minHeight: '146px',
                                maxHeight: '146px',
                                height: '146px',
                                background: '#FFFFFF',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                border: '3px solid #ffffff',
                                ":hover": {
                                    border: '3px solid #0969DA'
                                }
                            }}
                            onClick={() => {
                                postHogTrackingTdsClick(userId, userEmail)
                            }}
                        >

                            <Box sx={{
                                width: '168px',
                                minWidth: '168px',
                                maxWidth: '168px',
                                minHeight: '98px',
                                maxHeight: '98px',
                                height: '98px',
                                position: 'relative',
                                margin: '21px 0px 21px 21px',
                                display: 'flex',
                                flexDirection: 'column',

                            }}>

                                <Box sx={{

                                    minHeight: '22px',
                                    maxHeight: '22px',
                                    height: '22px',
                                    minWidth: '112px',
                                    width: '112px',
                                    maxWidth: '112px',
                                }}>
                                    <Label
                                        sx={{
                                            background: '#2155A4',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            borderRadius: '3px'
                                        }}
                                    >
                                        Not released yet
                                    </Label>

                                </Box>



                                {/* <Text sx={{

                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    550
                                </Text> */}
                                <Text sx={{
                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '22px',
                                    color: '#24292F',
                                    marginTop: '4px'
                                }}>
                                    TDS
                                </Text>

                                <Box sx={{
                                    marginTop: '18px',
                                    width: '174px',
                                    height: '22px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <StyledOcticon size={15} color="#06AA8D" icon={ArrowUpIcon}></StyledOcticon>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '22px',
                                        color: '#000000',
                                        marginLeft: '8px',
                                    }}>
                                        1.10% Synced 4 days ago
                                    </Text>

                                </Box>


                            </Box>

                            <Box sx={{
                                width: '126px',
                                minWidth: '126px',
                                maxWidth: '126px',
                                minHeight: '126px',
                                maxHeight: '126px',
                                height: '126px',
                                position: 'relative',
                                margin: '10px 0px 10px 7px',
                            }}>

                                <img src={flyingImg} >
                                </img>

                            </Box>



                        </Box>




                    </Box>

                    <Button
                        variant="invisible"
                        sx={{
                            margin: '46px 0px 0px auto',
                            background: '#DDF4FF',
                            border: '1px solid rgba(84, 174, 255, 0.40)',
                            borderRadius: '100px',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: '600',
                            lineHeight: '18px',
                            padding: '9px 15px 9px 15px',
                            color: '#24292F'
                        }}
                        onClick={() => { showSurveyForm("") }}
                    //   onClick={() => { showSurveyForm("") }}
                    >
                        Send Feedback
                    </Button>

                    <GstFilingSnapshotComponent />
                    <CalandarComponent />


                </Box>




            </Box>






        </Box>
    )

}

export default TaxHeroPortalPlaywright