import React from "react"
import {Box, Button, Heading,Text,Dialog } from "@primer/react"
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionbyProjectsId, openCloseFreeTrialEndDialog, openCloseSubscriptionPlanDialog } from "../redux/features/profileSlice";
import { useLocation } from "react-router-dom";
import { defaultOrgOpenClose, upgradePlanOpenClose } from "../redux/features/contactSlice";
const freeTrialEndImg =`${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/free-trial-end.svg`
const parentBox = {
    position:'fixed',
    left:'0px',
    right:'0px',
    top:'0px',
    margin:'auto',
    height:'526px',
    maxHeight:'526px',
    maxWidth:'921px',
    minWidth:'921px',
    minHeight:'526px',
    width:'921px',
    overflow:'auto',
    background:'#FFFFFF',
    border:'1px solid #D0D7DE',
    borderRadius:'3px',
    zIndex:'1'
    
}

export default function FreeTrialEnd(){

    let hostName = window.location.hostname
    let host = window.location.host

    const dispatch = useDispatch() 
    const location = useLocation()
    
    const userId = useSelector((state)=> state.profileData.userId)
    const userEmail = useSelector((state)=> state.profileData.userEmail)
    const subscriptionData = useSelector((state) => state.profileData?.subscriptionData)
    const [showUpgradeNowBtn,setshowUpgradeNowBtn] = React.useState(false)
   
    const freetrialEndOpen = useSelector((state)=> state.profileData.freetrialEndOpen)
    const freeTrialCustomerId = useSelector((state)=> state.profileData.freeTrialCustomerId)

    const closeFreeTrialEnd = (props) => {

        dispatch(openCloseFreeTrialEndDialog({
            freetrialEndOpen:false,
            freeTrialCustomerId:''
        }))

    }

    const showFreeTrialEnd = (props) => {

        dispatch(openCloseFreeTrialEndDialog({
            freetrialEndOpen: true,
            freeTrialCustomerId: ''
        }))

    }
    
    const upgradeSubscriptionPlan =async (props) => {
   

        if(hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com"){
             
            closeFreeTrialEnd("")
            dispatch(openCloseSubscriptionPlanDialog({ openCloseSubscriptionPlan: true }))
        }else{
            closeFreeTrialEnd("")
            dispatch(upgradePlanOpenClose({
                upgradeisOpen: true
            }))

        }

       
         


    }

   
    const getSubscriptionData = (props) => {
        dispatch(getSubscriptionbyProjectsId({ userEmail: props })).then(({ payload }) => {
            if (payload) {
                const { subscription, customer } = payload.body[0] || {};
                const subscriptionstatus = subscription?.status;
                const customerId = customer?.id;

                if (subscriptionstatus === "cancelled") {
                    setshowUpgradeNowBtn(customerId === userId);
                    showFreeTrialEnd("");
                } else {
                    closeFreeTrialEnd("");
                }
            }
        });
    };
     
    const handleSwitchOrganization = (props) => {

        closeFreeTrialEnd("")
         
        dispatch(defaultOrgOpenClose({
            defaultOrgisOpen: true
        }))
           

    }

    

    React.useEffect(()=>{

         
         if(freeTrialCustomerId!==null&&freeTrialCustomerId!==""){

                  
                 if(freeTrialCustomerId===userId){

                    setshowUpgradeNowBtn(true)
                 }else{
                    setshowUpgradeNowBtn(false)

                 }

   
             
         }




    },[freeTrialCustomerId])


    React.useEffect(() => {


        

        if(
            location.pathname==="/" || 
            location.pathname==="/forgetpassword" || 
            location.pathname==="/signup" ||
            location.pathname==="/list" ||
            location.pathname==="/contact" ||
            location.pathname==="/timeline" ||
            location.pathname==="/files" ||
            location.pathname==="/action" ||
            location.pathname==="/report" ||
            location.pathname==="/apps" ||
            location.pathname==="/rules" ||
            location.pathname==="/settings" 
           )
            
            {


        }else{

            if (userEmail !== undefined && userEmail !== null) {
                getSubscriptionData(userEmail)
            } else {
                getSubscriptionData(localStorage.getItem("userEmail"))
            }

        }

       




    }, [location.pathname])


    


    return(

        <Dialog 
     
        isOpen={freetrialEndOpen}
        sx={{
           height: '526px',
           maxHeight: '526px',
           maxWidth: '921px',
           minWidth: '921px',
           minHeight: '526px',
           width: '921px',
        }}
        onDismiss={()=>{
           //  closeFreeTrialEnd("")
        }}
      >
        <Box sx={parentBox} >

           <Box sx={{

            position:'absolute',
            top:'35px',
            left:'40px',
            right:'40px',
            height:'fit-content',
            display:'flex',
            flexDirection:'column',
            gap:'5px',
           
           }}>
           
              <Heading sx={{
                  fontFamily:'Segoe UI',
                  fontStyle:'normal',
                  fontWeight:'600',
                  fontSize:'20px',
                  lineHeight:'30px',
                  color:'#24292E',
                  letterSpacing:'0.3px'
              }}>
               Your free trial has ended !
              </Heading>
               

           </Box>

           

            <Box sx={{
                  height:'fit-content',
                  width:'fit-content',
                  position:'relative',
                  margin:'83px auto 0px auto'
            }}>
                <img src={freeTrialEndImg}  />
            </Box>

            <Box sx={{
                  height:'fit-content',
                  width: '770px',
                  minWidth:'770px',
                  maxWidth:'770px',
                  position: 'relative',
                  margin: '19px auto 0px auto',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center'
            }}>
                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292E',
                    textAlign: 'center',
                    letterSpacing:'0.3px'
                }}>
                        {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ?
                            `To continue using our service you need to upgrade to a paid plan! Taxhero is an automation tool that simplifies your tax compliance by fetching all the relevant information from the Income Tax, GST, and TDS websites.`
                            : `Upgrade now to unlock the full potential of our practice management tool  and take your business to the next level.
                    Click the 'Upgrade Now' button on your dashboard to get started.`

                        }
                    
                   
                </Text>
              
            </Box>

 

                {showUpgradeNowBtn === true ?

                    <Box sx={{
                        position: 'relative',
                        margin: '41px auto 0px auto',
                        height: 'fit-content',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}>



                        <Button

                            variant="outline"
                            sx={{
                                width: '250px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#0969DA',
                                borderRadius: '3px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                border: 'none',
                                color: '#FFFFFF'
                            }}
                            onClick={() => {
                                upgradeSubscriptionPlan("")
                            }}
                        >
                            Upgrade Now
                        </Button>
                        <Button

                            variant="invisible"
                            sx={{
                                width: '250px',
                                height: '40px',
                                display:hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com"?"none": 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '3px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                border: 'none',

                            }}
                            onClick={() => {
                                handleSwitchOrganization("")
                            }}
                        >
                            Switch organization
                        </Button>
                    </Box>

                    :
                    <Box sx={{
                        position: 'relative',
                        margin: '41px auto 0px auto',
                        height: 'fit-content',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}>




                        <Button

                            variant="invisible"
                            sx={{
                                width: '250px',
                                height: '40px',
                                display:hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com"?"none": 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '3px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                border: 'none',

                            }}
                            onClick={() => {
                                handleSwitchOrganization("")
                            }}
                        >
                            Switch organization
                        </Button>
                    </Box>

                }

           

       

    </Box>
    </Dialog>
    )

}
