import React from "react"
import {Box, Button, Heading,Text,Dialog,IconButton } from "@primer/react"
import { useDispatch, useSelector } from "react-redux";
import { checkUserSubscriptionStatus, openCloseSubscriptionPlanDialog, openCloseTaxheroFreeTrialEndDialog } from "../redux/features/profileSlice";
import { useLocation } from "react-router-dom";
import { XIcon } from "@primer/octicons-react";   
import customThemVariable from  "../../ThemeVariable.module.scss"
import SubscriptionPlans from "../Portal/SubscriptionPlans";
 
const parentBox = {
    position:'fixed',
    left:'0px',
    right:'0px',
    top:'0px',
    margin:'auto',
    height:'400px',
    maxHeight:'400px',
    maxWidth:'760px',
    minWidth:'760px',
    minHeight:'400px',
    width:'760px',
    overflow:'auto',
    background:'#FFFFFF',
    border:'1px solid #EC677C',
    borderRadius:'3px',
    zIndex:'1'
    
}

 
export default function FreeTrialEndTaxHero(){

    let hostName = window.location.hostname
    let host = window.location.host

    const dispatch = useDispatch() 
    const location = useLocation()
    
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    
    const taxherofreetrialEndOpen = useSelector((state) => state.profileData.taxherofreetrialEndOpen)
    
    const closeFreeTrialEnd = (props) => {

        dispatch(openCloseTaxheroFreeTrialEndDialog({
            taxherofreetrialEndOpen: false,
        }))

    }

    const showFreeTrialEnd = (props) => {

        dispatch(openCloseTaxheroFreeTrialEndDialog({
            taxherofreetrialEndOpen: true,
        }))

    }
    
    const upgradeSubscriptionPlan = async (props) => {

        closeFreeTrialEnd("")
        dispatch(openCloseSubscriptionPlanDialog({ openCloseSubscriptionPlan: true }))
        
    }


    



 

    return(
        <>
        <Dialog 
       isOpen={taxherofreetrialEndOpen}
        sx={{
           height: '400px',
           maxHeight: '400px',
           maxWidth: '760px',
           minWidth: '760px',
           minHeight: '400px',
           width: '760px',
        }}
        onDismiss={()=>{  }}
      >

        <Box sx={parentBox} >

                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: 'transparent'
                    }}
                    icon={() => <XIcon />}
                    onClick={() => {
                        closeFreeTrialEnd("")
                    }}
                />

                <Box sx={{
                    position: 'absolute',
                    top: '52px',
                    left: '30px',
                    right: '30px',
                    height: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',

                }}>
                    <Heading sx={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '37px',
                        lineHeight: '60px',
                        color:customThemVariable.primaryTextColor
                    }}>
                        Your 7 days free trial has expired , To continue using our service you need to upgrade to a paid plan!
                    </Heading>

                    <Text sx={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                       // color: '#586069',
                        color:customThemVariable.secondaryTextColor
                    }}>
                        Taxhero is an automation tool that simplifies your tax compliance by fetching all<br></br> the relevant information from the Income Tax, GST, and TDS websites.
                    </Text>
                </Box>

           
                <Box sx={{
                    position: 'absolute',
                    bottom: '24px',
                    left: '20px',
                    right: '20px',
                    height: 'fit-content',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                }}>

                    <Button
                        variant="outline"
                        sx={{
                            width: '250px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#0969DA',
                            borderRadius: '3px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            border: 'none',
                            color: '#FFFFFF'
                        }}
                        onClick={() => {
                            upgradeSubscriptionPlan("")
                        }}
                    >
                        Click here to upgrade
                    </Button>

                </Box>
        
    </Box>
    </Dialog>
     <SubscriptionPlans/>
    </>
    )

}
